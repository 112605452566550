/** TideOver Custom CSS **/
.App {
  height: 100vh;
  display: flex;
  flex-grow: 1;
}

#root {
  flex-grow: 1;
  display: flex;
}

.tos-anim1 {
  opacity: 0;
  animation: fade-in-bottom ease 1s forwards;
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tos-anim2 {
  transform: scaleX(0);
  transform-origin: left;
  animation: grow-left cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s forwards;
  animation-delay: 0.5s;
}

@keyframes grow-left {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.tos-anim3 {
  opacity: 0;
  animation: fade-in-right ease 1s forwards;
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
