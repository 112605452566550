@font-face {
  font-family: 'Rakesly';
  font-style: normal;
  font-weight: 400;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly rg.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: normal;
  font-weight: 400;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly bk.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: italic;
  font-weight: 400;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly bk it.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: italic;
  font-weight: 400;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly rg it.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: normal;
  font-weight: 250;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly el.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: normal;
  font-weight: 250;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly ul.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: italic;
  font-weight: 250;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly el it.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: italic;
  font-weight: 250;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly ul it.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: normal;
  font-weight: 300;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly lt.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: italic;
  font-weight: 300;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly lt it.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: normal;
  font-weight: 700;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly bd.woff') format('woff');
}
@font-face {
  font-family: 'Rakesly';
  font-style: italic;
  font-weight: 700;
  src: local('Rakesly'),
    url('https://fonts.cdnfonts.com/s/22964/rakesly bd it.woff') format('woff');
}
